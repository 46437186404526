import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StampWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  margin-top: 40px;
  /* margin: 0 auto 10px; */
`;

export default function Stamp() {
  return (
    <StampWrapper>
      <StaticImage
        placeholder="none"
        backgroundColor="transparent"
        src="../../src/assets/images/stamp.png"
        alt="aeriz stamp mark"
        className="aeriz-stamp"
        layout="fixed"
        height={115}
        width={113}
      />
    </StampWrapper>
  );
}
